import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import "./TeamImage.scss"

const TeamImage = () => {
  return (
    <div className="root-team-image">
      <StaticImage
        className="root-team-image-image"
        src={"../assets/Team.jpg"}
        placeholder="blurred"
        alt={"Kezios team"}
      />
    </div>
  )
}

export default TeamImage
