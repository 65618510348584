import BubbleCTA from "@components/BubbleCTA/BubbleCTA"
import Button from "@components/ui/Button/Button"
import { Body } from "@components/ui/Typography/Typography"
import React from "react"

import "./TeamCTA.scss"

const TeamCTA = () => {
  return (
    <BubbleCTA userId="justin" className="team-cta-bubble-cta">
      <Body>Want to discover our projects ?</Body>
      <Button variant="tertiary" to={"/projects"}>
        Our projects
      </Button>
    </BubbleCTA>
  )
}

export default TeamCTA
