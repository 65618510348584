import * as React from "react"

import "./articles.scss"
import SEO from "@components/SEO/SEO"
import Layout from "@components/layout/layout"
import TeamHero from "@landingsParts/Team/TeamHero/TeamHero"
import Awards from "@landingsParts/Home/HomeAwards/HomeAwards"
import TeamImage from "@landingsParts/Team/TeamImage/TeamImage"
import TeamCTA from "@landingsParts/Team/TeamCTA/TeamCTA"

// markup
const TeamPage = () => {
  return (
    <main>
      <Layout>
        <SEO
          title="Kezios - Our projects"
          description={`The Kezios members.`}
        />

        <TeamHero />
        <Awards />
        <TeamCTA />
        <TeamImage />
      </Layout>
    </main>
  )
}

export default TeamPage
