import React from "react"

import CenteredContent from "@components/CenteredContent/CenteredContent"
import { Body, Heading } from "@components/ui/Typography/Typography"

import "./TeamHero.scss"
import AvatarGroup from "@components/AvatarGroup/AvatarGroup"

const TeamHero = () => {
  return (
    <section className="team-hero-container">
      <CenteredContent>
        <Heading>Our team</Heading>
        <Body>Discover our team members, ready to join your team !</Body>
      </CenteredContent>
      <div className="team-hero-curve-container">
        <div className="team-hero-curve" />
        <div className="team-hero-img-container">
          <AvatarGroup variant="large" />
        </div>
      </div>
    </section>
  )
}

export default TeamHero
